import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  GetOrgIntegrationSettingsPayload,
  GetOrgIntegrationSettingsResponse,
} from "../types/get-org-integration-settings.types";
import { GenericAPIResponse } from "../../services/utils";
import { LeadStage } from "./types/get-org-lead-stages.types";
import { LeadGroup } from "./types/get-org-lead-groups.types";
import { LeadStageType } from "./types/get-lead-stage.types";
import {
  CreateLeadStagePayload,
  CreateLeadStageResponse,
} from "./types/create-lead-stage.types";
import { LeadGroupType } from "./types/get-lead-groups.types";
import {
  UpdateLeadStagePayload,
  UpdateLeadStageResponse,
} from "./types/update-lead-stage.types";
import {
  ChangeLeadStagePositionPayload,
  ChangeLeadStagePositionResponse,
} from "./types/change-lead-stage-position.types";
import {
  CreateLeadGroupPayload,
  CreateLeadGroupResponse,
} from "./types/create-lead-group.types";
import {
  UpdateLeadGroupPayload,
  UpdateLeadGroupResponse,
} from "./types/update-lead-group.types";
import { CustomerFilter } from "./types/customer-search.types";
import { Category } from "./types/category.types";
import { WhatsappData } from "./types/get-whatsapp-data.types";
import { Plan } from "./types/plan.types";
import { GetSubscriptionResponse } from "./types/get-subscription.types";
import {
  CreateSubscriptionPayload,
  CreateSubscriptionResponse,
} from "./types/create-subscription.types";
import { AddOrgNotePayload } from "./types/add-org-note.types";
import { CreateBankPaymentPayload } from "./types/create-bank-payment.types";
import { EndSubscriptionResponse } from "./types/end-subscription.types";
import { UserDetails } from "./types/get-user-details.types";
import {
  GetCallHistoryPayload,
  GetCallHistoryResponse,
} from "./types/get-call-history.types";
import { convertToSearchParams } from "../../utility/convert-to-search-params";
import { GetTrialCallHistoryPayload } from "./types/get-trial-call-history.types";
import { getTrialHistoryApiFilter } from "../../views/superfone/call-history/store/action";
import { UpdateOrgPayload } from "./types/update-org.types";
import { CreateOrgPayload } from "./types/create-org.types";
import {
  PresignURLPayload,
  PresignURLResponse,
} from "./types/presign-url.types";

export const superfoneApi = createApi({
  reducerPath: "superfoneApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
    credentials: "include",
  }),
  endpoints: (builder) => ({
    getOrgIntegrationSettings: builder.query<
      GetOrgIntegrationSettingsResponse,
      GetOrgIntegrationSettingsPayload
    >({
      query: (payload) => {
        const { orgId, page, page_size } = payload;
        return {
          url: `/admin/integration/setting/org/${orgId}?page=${
            page || 1
          }&page_size=${page_size || 10}`,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<GetOrgIntegrationSettingsResponse>
      ) => {
        return response.data;
      },
    }),
    getLeadStageTypes: builder.query<LeadStageType[], void>({
      query: () => ({
        url: `/lead-stage/types`,
      }),
      transformResponse: (response: GenericAPIResponse<LeadStageType[]>) =>
        response.data,
    }),
    getLeadGroupTypes: builder.query<LeadGroupType[], void>({
      query: () => ({
        url: `/lead-group/types`,
      }),
      transformResponse: (response: GenericAPIResponse<LeadGroupType[]>) =>
        response.data,
    }),
    createLeadStage: builder.mutation<
      CreateLeadStageResponse,
      CreateLeadStagePayload
    >({
      query: (body) => ({
        url: `/admin/lead-stage`,
        method: "post",
        body,
      }),
      transformResponse: (
        response: GenericAPIResponse<CreateLeadStageResponse>
      ) => response.data,
    }),
    updateLeadStage: builder.mutation<
      UpdateLeadStageResponse,
      UpdateLeadStagePayload
    >({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `/admin/lead-stage/${id}`,
          method: "put",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<UpdateLeadStageResponse>
      ) => response.data,
    }),
    changeLeadStagePosition: builder.mutation<
      ChangeLeadStagePositionResponse,
      ChangeLeadStagePositionPayload
    >({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `/admin/lead-stage/${id}/change-position`,
          method: "put",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<ChangeLeadStagePositionResponse>
      ) => response.data,
    }),
    createLeadGroup: builder.mutation<
      CreateLeadGroupResponse,
      CreateLeadGroupPayload
    >({
      query: (body) => {
        return {
          url: `/admin/lead-group`,
          method: "post",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<CreateLeadGroupResponse>
      ) => response.data,
    }),
    updateLeadGroup: builder.mutation<
      UpdateLeadGroupResponse,
      UpdateLeadGroupPayload
    >({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `/admin/lead-group/${id}`,
          method: "put",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<UpdateLeadGroupResponse>
      ) => response.data,
    }),
    getOrgLeadStages: builder.query<LeadStage[], number>({
      query: (orgId) => ({
        url: `/admin/lead-stage/org/${orgId}`,
      }),
      transformResponse: (response: GenericAPIResponse<LeadStage[]>) => {
        return response.data;
      },
    }),
    getOrgLeadGroups: builder.query<LeadGroup[], number>({
      query: (orgId) => ({
        url: `/admin/lead-group/org/${orgId}`,
      }),
      transformResponse: (response: GenericAPIResponse<LeadGroup[]>) => {
        return response.data;
      },
    }),
    deleteLeadStage: builder.mutation<any, number>({
      query: (id) => {
        return {
          url: `/admin/lead-stage/${id}`,
          method: "delete",
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    deleteLeadGroup: builder.mutation<any, number>({
      query: (id) => {
        return {
          url: `/admin/lead-group/${id}`,
          method: "delete",
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getCustomerFilters: builder.query<CustomerFilter[], void>({
      query: () => {
        return {
          url: "/customer/filters",
        };
      },
      transformResponse: (response: GenericAPIResponse<CustomerFilter[]>) =>
        response.data,
    }),
    getCategories: builder.query<Category[], void>({
      query: () => ({ url: "/categories" }),
      transformResponse: (response: GenericAPIResponse<Category[]>) =>
        response.data,
    }),
    getWhatsappData: builder.query<WhatsappData, number>({
      query: (orgId) => ({
        url: `/whatsapp/heltar/${orgId}`,
      }),
      transformResponse: (response: GenericAPIResponse<WhatsappData>) =>
        response.data,
    }),
    getAllPlans: builder.query<Plan[], void>({
      query: () => ({
        url: "subscription/plans/all",
      }),
      transformResponse: (response: GenericAPIResponse<Plan[]>) =>
        response.data,
    }),
    getSubscriptionById: builder.query<GetSubscriptionResponse, number>({
      query: (subscriptionId: number) => ({
        url: `subscription/${subscriptionId}`,
      }),
      transformResponse: (
        response: GenericAPIResponse<GetSubscriptionResponse>
      ) => response.data,
    }),
    endSubscription: builder.mutation<EndSubscriptionResponse, number>({
      query: (subscriptionId) => ({
        url: `/subscription/end/${subscriptionId}`,
        method: "post",
      }),
      transformResponse: (
        response: GenericAPIResponse<EndSubscriptionResponse>
      ) => response.data,
    }),
    createSubscription: builder.mutation<
      CreateSubscriptionResponse,
      CreateSubscriptionPayload
    >({
      query: (body) => ({
        url: "/subscription",
        method: "post",
        body,
      }),
      transformResponse: (
        response: GenericAPIResponse<CreateSubscriptionResponse>
      ) => response.data,
    }),
    addOrgNote: builder.mutation<any, AddOrgNotePayload>({
      query: (payload) => {
        const note = `Action: ${payload.action_name}, comment: ${payload.comment}`;
        return {
          url: `/org/notes`,
          method: "post",
          body: {
            org_id: payload.org_id,
            note,
          },
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    createBankPayment: builder.mutation<any, CreateBankPaymentPayload>({
      query: (body) => ({
        url: "/payments/bank-payment",
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getUserDetails: builder.query<UserDetails, number>({
      query: (userId) => ({
        url: `/users/${userId}`,
      }),
      transformResponse: (response: GenericAPIResponse<UserDetails>) =>
        response.data,
    }),
    deletePayment: builder.mutation<any, number>({
      query: (paymentId) => ({
        url: `/orders/payments/${paymentId}`,
        method: "delete",
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    refundBankPayment: builder.mutation<
      any,
      { payment_id: any; org_id: number }
    >({
      query: (body) => ({
        url: `/payments/bank-payment`,
        method: "delete",
        body: body,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getCallHistory: builder.query<
      GetCallHistoryResponse,
      GetCallHistoryPayload
    >({
      query: (payload) => {
        const { org_id, ...rest } = payload;
        const query = convertToSearchParams(rest);
        return {
          url: `/call-history/org/${org_id}${query}`,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<GetCallHistoryResponse>
      ) => response.data,
    }),
    getTrialCallHistory: builder.query<
      GetCallHistoryResponse,
      GetTrialCallHistoryPayload
    >({
      query: (payload) => {
        const {
          durationRange,
          page_size,
          text,
          status,
          call_type,
          include_soft_deleted,
        } = payload;
        const apiFilter = getTrialHistoryApiFilter(durationRange);

        const query = convertToSearchParams({
          page_size,
          text,
          status,
          call_type,
          include_soft_deleted,
          ...apiFilter,
        });
        return {
          url: `/call-history/trial${query}`,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<GetCallHistoryResponse>
      ) => response.data,
    }),
    createOrg: builder.mutation<any, CreateOrgPayload>({
      query: (body) => {
        return { url: `/admin/org`, method: "post", body };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    updateOrg: builder.mutation<any, UpdateOrgPayload>({
      query: (payload) => {
        const { org_id, ...body } = payload;
        return { url: `/org/${org_id}`, method: "put", body };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    startCRMTrial: builder.mutation<any, {
      "org_id": number,
      "start_date":string;
      "valid_till": string;
  }>({
      query: (body) => ({
        url: `admin/org/start-crm-trial`,
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    fupToggle: builder.mutation<
      any,
      { org_id: number; is_fup_kyc_required: boolean }
    >({
      query: (payload) => {
        const { org_id, ...body } = payload;
        return {
          url: `/org/kyc/${org_id}/fup/toggle`,
          method: "post",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    updateFupKYC: builder.mutation<
      any,
      { org_id: number; fup_status?: string; pofup?: string }
    >({
      query: (body) => ({
        url: `/org/kyc/modify`,
        method: "put",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    presignURL: builder.mutation<PresignURLResponse, PresignURLPayload>({
      query: (payload) => ({
        url: `/s3/presignURL?file_name=${payload.file_name}&file_type=${
          payload.file_type
        }${payload?.public ? `&public=true` : ""}`,
        method: "get",
      }),
      transformResponse: (response: GenericAPIResponse<PresignURLResponse>) =>
        response.data,
    }),
    copyFUPKYC: builder.mutation<
      any,
      {
        source_org_id: number;
        destination_org_id: number;
        comment: string;
      }
    >({
      query: (body) => ({
        url: `/admin/org/kyc/copy/fup`,
        body,
        method: "post",
      }),
      transformResponse: (response: any) => response.data,
    }),
    getDigioRequestsForFUP: builder.query<any, number>({
      query: (org_id) => ({
        url: `digio/org/${org_id}/fup`,
      }),
      transformResponse: (response: any) => response.data,
    }),
    createDigioFUPRequest: builder.mutation<
      any,
      {
        org_id: number;
        email: string;
      }
    >({
      query: (body) => ({
        url: `/admin/org/kyc/digio/fup`,
        method: "post",
        body,
      }),
      transformResponse: (response: any) => response.data,
    }),
    downloadFUPDocument: builder.mutation<
      any,
      {
        document_id: string;
      }
    >({
      query: (body) => ({
        url: `/admin/org/kyc/digio/fup/download`,
        method: "post",
        body,
      }),
      transformResponse: (response: any) => response.data,
    }),
    
  }),
});

export const {
  useGetOrgIntegrationSettingsQuery,
  useGetLeadGroupTypesQuery,
  useCreateLeadStageMutation,
  useUpdateLeadStageMutation,
  useChangeLeadStagePositionMutation,
  useCreateLeadGroupMutation,
  useUpdateLeadGroupMutation,
  useGetOrgLeadStagesQuery,
  useGetOrgLeadGroupsQuery,
  useLazyGetOrgLeadStagesQuery,
  useLazyGetOrgLeadGroupsQuery,
  useDeleteLeadStageMutation,
  useDeleteLeadGroupMutation,
  useGetCustomerFiltersQuery,
  useGetLeadStageTypesQuery,
  useGetCategoriesQuery,
  useGetWhatsappDataQuery,
  useGetAllPlansQuery,
  useGetSubscriptionByIdQuery,
  useEndSubscriptionMutation,
  useCreateSubscriptionMutation,
  useAddOrgNoteMutation,
  useCreateBankPaymentMutation,
  useGetUserDetailsQuery,
  useLazyGetUserDetailsQuery,
  useDeletePaymentMutation,
  useRefundBankPaymentMutation,
  useLazyGetCallHistoryQuery,
  useLazyGetTrialCallHistoryQuery,
  useUpdateOrgMutation,
  useCreateOrgMutation,
  useStartCRMTrialMutation,
  useFupToggleMutation,
  useUpdateFupKYCMutation,
  usePresignURLMutation,
  useCopyFUPKYCMutation,
  useGetDigioRequestsForFUPQuery,
  useCreateDigioFUPRequestMutation,
  useDownloadFUPDocumentMutation,
} = superfoneApi;
